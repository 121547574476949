.root {
  padding: 12px 24px;
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 24px 0px;
    .editing{
      display: flex;
      align-items: center;
    }
    .select {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .footer {
      margin-top: 16px;
      display: flex;
      gap: 16px;
      margin-left: auto;
      .run {
        background-color: #4b0082;
        border: 1px solid #4b0082;
        border-radius: 8px;
        font-size: 14px;
        padding: 6px 12px;
        color: #fff;
        font-weight: 500;
        text-transform: none;
      }
      .cancel {
        text-transform: none;
        color: red;
        border: 1px solid red;
        border-radius: 8px;
        font-size: 14px;
        padding: 6px 12px;
        font-size: 500;
      }
    }
  }
}

.workflowView {
  margin-left: 300px;
  margin-top: 64px;
  margin-bottom: 64px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .wrapper {
      width: 50vw;
      border: 1px solid #e5e5e5;
      border-width: 1px 0px 1px 0px;
      .linearPrimary {
        background-color: #faf8ff;
      }
      .linearBarPrimary {
        background-color: #4b0082;
      }
      .tile {
        display: flex;
        flex-direction: row;
        padding: 12px 0px 12px 0px;
        font-size: 14px;
        gap: 16px;
        align-items: center;
        .connectionName {
          font-weight: 400;
        }
        .trainingLabel {
          color: #bab1c1;
          font-weight: 400;
        }
        button {
          text-transform: none;
          margin-left: auto;
        }

        .iconGroup {
          margin-left: auto;
          .chatIcon {
            svg {
              font-size: 18px;
              color: #000;
            }
          }
        }
      }
    }
  }
  .noWorkflows {
    margin: 32px 64px;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    text-align: center;
    padding: 32px;
    opacity: 0.6;
  }
}

.dialog {
  .paper {
    min-width: 60vw;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }
  .content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title {
      width: fit-content;
      display: inline !important;
      font-size: 18px;
      font-weight: 600;
    }
    .steps {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .step {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 8px;
        .label {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .addBtn {
      justify-content: flex-start;
      text-transform: none;
      width: fit-content;
    }
    .footer {
      display: flex;
      gap: 16px;
      margin-left: auto;
      .run {
        background-color: #4b0082;
        border: 1px solid #4b0082;
        border-radius: 8px;
        font-size: 14px;
        padding: 6px 12px;
        color: #fff;
        font-weight: 500;
        text-transform: none;
      }
      .cancel {
        text-transform: none;
        color: red;
        border: 1px solid red;
        border-radius: 8px;
        font-size: 14px;
        padding: 6px 12px;
        font-size: 500;
      }
    }
  }
}

.popover {
  .paper {
    min-width: 6rem;
  }
  .options {
    padding: 0px 4px;

    .optionBtn {
      width: 100%;
      margin: auto;
      text-transform: none;
      font-size: 12px;
    }
  }
}
