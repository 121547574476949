.files {
  margin-left: 300px;
  margin-top: 64px;
  margin-bottom: 64px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .title {
    font-size: 24px;
    font-weight: 600;
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .wrapper {
      width: 50vw;
      border: 1px solid #e5e5e5;
      border-width: 1px 0px 1px 0px;
      .linearPrimary {
        background-color: #faf8ff;
      }
      .linearBarPrimary {
        background-color: #4b0082;
      }
      .tile {
        display: flex;
        flex-direction: row;
        padding: 12px 0px 12px 0px;
        font-size: 14px;
        gap: 16px;
        align-items: center;
        .connectionName {
          font-weight: 400;
        }
        .trainingLabel {
          color: #bab1c1;
          font-weight: 400;
        }
        button {
          text-transform: none;
          margin-left: auto;
        }

        .iconGroup {
          margin-left: auto;
          .chatIcon {
            svg {
              font-size: 18px;
              color: #000;
            }
          }
        }
      }
    }
  }
}

.dialog {
  .paper {
    min-width: 90vw;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }
}

.fileIcon{
  cursor: pointer;
}

.popover {
  .options {
    padding: 0px 4px;

    .optionBtn {
      text-transform: none;
      font-size: 12px;
    }
  }

  .deleteOptions{
    padding: 0px 4px;
    .optionBtn {
      width: 100%;
      justify-content: flex-start;
      text-transform: none;
      font-size: 12px;
      color: #ff0000;
    }
  }
}

.dialogWorkflow {
  .paper {
    min-width: 50vw;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }
}