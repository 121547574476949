.dialog {
  .paper {
    margin-left: 300px;
    min-width: 500px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }
  .subtitle {
    font-size: 12px;
    color: rgb(133, 133, 133);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .tile {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .title {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .footer {
    margin-left: auto;

    .button {
      text-transform: none;
      margin-right: 20px;
      margin-bottom: 12px;
      background: #4b0082;
      font-weight: 800;
      color: #fff;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
