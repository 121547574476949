.drawer {
  .paper {
    background-color: #f5f5f5;
  }
  .logo{
    width: 150px;
    margin: 0px 10px;
  }
  .newchat {
    background-color: #fff;
    margin: 24px;
    border-radius: 12px;
    border: 1px solid #cccccc;
    svg {
      color: #000;
      font-size: 18px;
    }
    span {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }
    .link {
      display: flex;
      gap: 8px;
      text-decoration: none;
      align-items: center;
      margin-left: 25%;
      width: 100%;
    }
  }
  .item {
    margin: 4px 24px;
    height: 2.4rem;
    border-radius: 8px;
    svg {
      color: #64748b;
      font-size: 18px;
    }
    span {
      color: #64748b;
      font-size: 14px;
      font-weight: 600;
    }
    .link {
      display: flex;
      gap: 12px;
      text-decoration: none;
      align-items: center;
      width: 100%;
    }
  }
  .active {
    span {
      font-weight: 800;
    }
  }
  .footerItem {
    margin: 4px 24px;
    height: 2.4rem;
    border-radius: 8px;
    width: 85%;
    svg {
      color: #64748b;
      font-size: 18px;
    }
    span {
      color: #64748b;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.history {
  max-height: 24vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.history::-webkit-scrollbar {
  display: none;
}
