.root {
  margin-left: 300px;
  height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  padding: 12px;
  .previewContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .preview {
      cursor: pointer;
      display: flex;
      gap: 4px;
      padding: 6px;
      width: fit-content;
      align-items: center;
      border: 1px solid #dad1ec;
      border-radius: 8px;
      img {
        width: 22px;
      }
      font-size: 12px;
      font-weight: 500;
    }
    .rightPreview {
      display: flex;
      gap: 8px;
      .rightButton {
        background-color: #fff;
        border: 1px solid #4b0082;
        border-radius: 8px;
        font-size: 12px;
        padding: 6px 12px;
        color: #4b0082;
        font-weight: 500;
        text-transform: none;
        &:hover {
          background-color: #faf8ff;
        }
        svg {
          color: #4b0082;
          font-size: 20px;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .welcome {
    width: 60%;
    margin: 20% auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .title {
      margin: auto;
      font-size: 24px;
      font-weight: 600;
    }
    .textbox {
      box-shadow: 0px 0px 8px 0px rgba(104, 34, 155, 0.3);
      border-radius: 8px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .preview {
        display: flex;
        cursor: pointer;
        gap: 4px;
        padding: 6px;
        width: fit-content;
        align-items: center;
        border: 1px solid #dad1ec;
        border-radius: 8px;
        img {
          width: 22px;
        }
        font-size: 12px;
        font-weight: 500;
      }
      .footer {
        display: flex;
        gap: 4px;
        .button {
          color: #4b0082;
          font-weight: 500;
          text-transform: none;
          border-radius: 12px;
          &:disabled {
            opacity: 0.5;
          }
          &:hover {
            background-color: #faf8ff;
          }
        }
        .rightButton {
          margin-left: auto;
          background-color: #fff;
          border: 1px solid #4b0082;
          svg {
            color: #4b0082;
            font-size: 20px;
          }
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .chatParent {
    max-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 128px;
    .chatsContainer {
      width: calc(100% - 64px);
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 80vh;
      overflow: auto;
      .logoTile {
        margin-top: 10px;
        border-radius: 50%;
      }
      .chatWrapper {
        display: flex;
        flex-direction: column;
        .chat {
          display: flex;
          gap: 12px;
          // align-items: center;
          .content {
            display: flex;
            flex-direction: column;
            width: 100%;
            .loading {
              height: 100%;
              display: flex;
              align-items: center;
            }
            .markdown {
              display: block;
              font-size: 14px;
              padding-right: 12px;

              pre {
                font-size: 14px;
                code {
                  border-radius: 8px;
                  font-weight: 500;
                }
              }
            }
            .accordion {
              border-radius: 8px;
              .accordionSummary {
                justify-content: flex-start;
                font-weight: 600;
                font-size: 12px;
                min-height: 0px;
                padding: 0px 32px;
                svg {
                  color: #4b0082;
                  font-size: 20px;
                }
              }
            }
          }
        }
        .downloadFiles {
          display: flex;
          gap: 8px;
          align-items: center;
          padding-left: 24px;
          .button {
            color: #4b0082;
            font-weight: 500;
            text-transform: none;
            border-radius: 12px;
            &:disabled {
              opacity: 0.5;
            }
            &:hover {
              background-color: #faf8ff;
            }
          }
        }
      }
    }
    .rightButton {
      margin-left: auto;
      background-color: #fff;
      svg {
        color: #4b0082;
        font-size: 20px;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.dialog {
  .paper {
    min-width: 90vw;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(104, 34, 155, 0.3);
  }
}
